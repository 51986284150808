import "./Main.css";
import Router from "../Router/Router";
import React from "react";

export default function Main(){
    // const variables go here
    // async functions go here

    return(
        <React.Fragment>
            <div className="main">
                <Router />  
            </div>
        </React.Fragment>
    )
}